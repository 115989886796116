import React, {Fragment, useEffect, useState} from 'react';
import {setPop} from "../actions/commonActions";
import {connect} from 'react-redux';
import Parser from "html-react-parser";
import Axios from "axios";
import $ from 'jquery';

const CalendarPop = ({form, setForm, getFormatDate, setActiveCalendarPop, getDashboard}) => {
    let [items, setItems] = useState([]);

    useEffect(() => {
        Axios.get(window.domain + "/api/doctor/calendar?date=" + form.date.slice(0,7))
            .then(response => {
                setItems(response.data.list);

                initCalendar(response.data.list);
            });
    }, []);

    const initCalendar = (items) => {
        window.$('#datepicker').datepicker({
            onSelect: function (dateText, inst) {
                setForm({
                    ...form,
                    date: dateText
                });

                getDashboard({date: dateText});

                setActiveCalendarPop(false);
            },
            onRenderCell: function (date, cellType) {
                let targetItem = items.find(item => item.date == getFormatDate(date));

                let count = targetItem ? targetItem.count : 0;

                var currentDate = date.getDate();

                if (cellType == 'day') {
                    return {
                        html: currentDate + '<div class="dp-notes">' + count + '</div>'
                    }
                }
            },
            onChangeMonth: function(month, year){

            },
        });
    }

    return (
        <div className="m-pop m-pop-calendar type02">
            <div className="m-pop-inner">
                <div className="content">
                    <div id="datepicker" className="datepicker-here" data-language='ko'></div>
                </div>
            </div>
        </div>
    );
    return null;
};

const mapState = (state) => {
    return {
        pop: state.common.pop
    }
};

const mapDispatch = (dispatch) => {
    return {
        setPop: (data) => {
            dispatch(setPop(data));
        }
    }
};

export default connect(mapState, mapDispatch)(CalendarPop);
