/* global kakao */
import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import axios from "axios";
import {connect} from "react-redux";
import {login, setPop} from "../actions/commonActions";
import {useParams} from "react-router-dom";
import DaumPostcode from 'react-daum-postcode';
import Parser from 'html-react-parser';
import $ from 'jquery';
import Axios from "axios";
import { postcodeScriptUrl } from 'react-daum-postcode/lib/loadPostcode';
import {useDropzone} from 'react-dropzone'

const { kakao } = window;

const radioStyle = {

}

const btnLong_blue = {
    width:"100%",
    height:"40px",
    borderRadius:"10px",
    backgroundColor: "#59A9FF",
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px", 
    marginBottom: "20px"
};

const btnLong_green = {
    width:"100%",
    height:"40px",
    borderRadius:"10px",
    backgroundColor: "#9AC160",
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px", 
    marginBottom: "20px"
};

const divStyle = {
    display: "flex",
    alignItems: "center",
}

const spanStyle = {
    fontSize: "22px",
    marginTop: "22px",
    position: "absolute",
    marginLeft: "5px",
}

const btnStyle_blue = {
    color : "#59A9FF",
    margin: "5px",
    padding: "5px",
    borderRadius: "5px",
    backgroundColor: "white",
    marginLeft : "85%",
    fontSize: "14px",
};

const btnStyle_green = {
    color : "#9AC160",
    margin: "5px",
    padding: "5px",
    borderRadius: "5px",
    backgroundColor: "white",
    marginLeft : "75%",
    fontSize: "14px",
};

const DiagnosisShow = ({params, history, setPop}) => {
    // 증상
    let {id} = useParams();
    let [cnt, setCnt] = useState(0);
    
    const [patient, setPatient] = useState({
        images: []
    });

    const [medicalHistory,setMedicalHistory] = useState({});

    const [imageFile, setImageFile] = useState(null);

    const [medicalImage, setMedicalImage] = useState(null);

    const [prescriptionPath, setPrescriptionPath] = useState("");
    
    const [form, setForm] = useState({
        index: id,
        symptom: "", // 증상
        memo: "", // 메모

        medicalHistory:{
            medicalStatus : "",
            fileBlob : "",
            userId : "",
            medicalMoney : "",
        },

        disease: [
            {
                code: "",
                diagnosis: "",
                memo: ""
            },
            {
                code: "",
                diagnosis: "",
                memo: ""
            },
            {
                code: "",
                diagnosis: "",
                memo: ""
            },
            {
                code: "",
                diagnosis: "",
                memo: ""
            },
            {
                code: "",
                diagnosis: "",
                memo: ""
            },
            {
                code: "",
                diagnosis: "",
                memo: ""
            },
            {
                code: "",
                diagnosis: "",
                memo: ""
            },
            {
                code: "",
                diagnosis: "",
                memo: ""
            },
        ], // 진료 데이터 입력란
        medicine: [
            {
                code: "",
                prescription: "",
                onceDosage: "",
                dayDosage: "",
                daysDosage: "",
                usage: "",
            },
            {
                code: "",
                prescription: "",
                onceDosage: "",
                dayDosage: "",
                daysDosage: "",
                usage: "",
            },
            {
                code: "",
                prescription: "",
                onceDosage: "",
                dayDosage: "",
                daysDosage: "",
                usage: "",
            },
            {
                code: "",
                prescription: "",
                onceDosage: "",
                dayDosage: "",
                daysDosage: "",
                usage: "",
            },
            {
                code: "",
                prescription: "",
                onceDosage: "",
                dayDosage: "",
                daysDosage: "",
                usage: "",
            },
            {
                code: "",
                prescription: "",
                onceDosage: "",
                dayDosage: "",
                daysDosage: "",
                usage: "",
            },
            {
                code: "",
                prescription: "",
                onceDosage: "",
                dayDosage: "",
                daysDosage: "",
                usage: "",
            },
            {
                code: "",
                prescription: "",
                onceDosage: "",
                dayDosage: "",
                daysDosage: "",
                usage: "",
            },
        ], // 약 데이터 입력란
        price: "", // 진료비
    });

    useEffect(() => {
        getPatient();
        getMedicalImage();
        
        $(".dialog .btn_cancel").click(function(){
            $(".dialog").toggle();
        });

        $("#diagComplete").click(function(){
            $(".dialog").toggle();
        });

    }, []);

    // 이미지 파일 선택 시 호출되는 함수
    function handleFileUpload(event) {
        const selectedFile = event.target.files[0];
        setPrescriptionPath(selectedFile.name);
        setImageFile(selectedFile);
        setCnt(1);
    }
      
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if($("#price").val() <= 0){
            alert('진료비가 0원입니다. 진료비를 다시 입력하거나 진료비가 없는 경우 진료완료를 눌러주세요.');
            return;
        }

        if(imageFile == "" && medicalHistory == ""){
            alert('처방전이 첨부되지 않았습니다. 처방전을 다시 첨부하거나 처방전이 없는 경우 진료 완료를 눌러주세요.');
            return;
        }

        const formData = new FormData();
        formData.append("prescriptionFile",imageFile);
        formData.append("price",$("#price").val());
        formData.append("patient_id",patient.idx);
        formData.append("medical_key",$("#medical_key").val());

        await axios.patch(window.domain + "/api/doctor/medicalHistoryUpdate", formData, {
            
        }).then(res => {
            if (res.data.status === 200) {
                alert("수납이 완료되었습니다.");
                history.go(0);
            }
        }).catch(error => {
            
        });
    };

    const changeMedicalStatus = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("medical_status","진료완료");
        formData.append("status","3");
        formData.append("patient_id",patient.idx);
        formData.append("index",{id}.id);

        await axios.post(window.domain + "/api/doctor/medicalStatusUpdate", formData, {
            
        }).then(res => {
            if (res.data.status === 200) {
                alert("진료가 완료 되었습니다. ");
                history.push("/");
            }
        }).catch(error => {
            
        });
    }

    const diagnosisCancle = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("medical_status","진료대기");
        formData.append("status","1");
        formData.append("patient_id",patient.idx);
        formData.append("index",{id}.id);

        await axios.post(window.domain + "/api/doctor/medicalStatusUpdate", formData, {
            
        }).then(res => {
            if (res.data.status === 200) {
                history.go(-1);
            }
        }).catch(error => {
            
        });
    }

    const getPatient = () => {
        Axios.get(window.domain + "/api/doctor/karte/" + id)
            .then(response => {
                setPatient(response.data.data);
                getMedicalHistory(response.data.data.idx);
            });
    }

    const getMedicalHistory = (user_id) => {
        Axios.get(window.domain + "/api/doctor/medicalHistory/" + id)
            .then(response => {
                setMedicalHistory(
                    response.data.medicalHistory
                );
            });
    }

    const getMedicalImage = (user_id) => {
        Axios.get(window.domain + "/api/doctor/getMedicalImage/" + id)
            .then(response => {
                setMedicalImage(response.data.ret)
            });
    }

    const store = async (e) => {
        e.preventDefault();

        console.log(form);

        await axios.post(window.domain + "/api/doctor/prescription", {
            ...form,
            disease: JSON.stringify(form.disease),
            medicine: JSON.stringify(form.medicine),
        }).then(response => {
            if (response.data.status === 200) {
                setPop({
                    title: null,
                    description: `${patient.name} 님의 처방전이 전송되었습니다.`,
                    needLogo: true,
                    buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => {("/"); setPop(null)} }>확인</button>]
                });
            }
        }).catch(error => {
            setPop({
                title: null,
                description: error.response.data.message,
                needLogo: true,
                buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
            });
        });
    };

    function MyDropzone(){
        const onDrop = useCallback((acceptedFiles,fileRejections) => {
            if(fileRejections.length !== 0){
                alert("최대 파일 개수는 1개입니다.");
            }else{
                setCnt(acceptedFiles.length);
                setImageFile(acceptedFiles[0]);
                setMedicalHistory({
                    base64 : "",
                    medical_money: $("#price").val(),
                    medical_key: $("#medical_key").val(),
                });
                
            }
        }, [])
        
        const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({
            onDrop,
            maxFilesize: 5,                    //최대 파일 사이즈 (MB)
            maxFiles: 1,                       //첨부 개수
        });

        const files = acceptedFiles.map(file => (
            <li key={file.path} style={{listStyle:"disc"}}>
              {file.path} - {file.size} bytes
            </li>
        ));

        return (
            <section>
                <div {...getRootProps()} className="dropZone">
                    <input {...getInputProps()} />
                    <p>이곳을 클릭 또는 여러개의 처방전을 마우스로 끌어 넣으세요.</p>
                </div>
                <aside style={{paddingTop:"20px"}}>
                    <h4 style={{fontSize: "25px"}}>Files</h4>
                    <ul style={{marginLeft: "20px"}}>
                        {files}
                    </ul>
                </aside>
            </section>
        );
    };

    return (
        <div className="area-main area-admin">
            <form onSubmit={handleSubmit} id="form" name="frm" encType="multipart/form-data">
            <input type="hidden" id="medical_key" defaultValue={medicalHistory.medical_key}></input>
            <div className="dialog">
                <h1>수납요청 및 진료완료</h1>
                <p>수납 요청시 환자가 등록한 카드로 진료비가 자동 결제되며,<br/>진료완료 목록으로 이동합니다.</p>
                <input type="button" className="btn btn_cancel" value="취소"></input>
                <input type="submit" className="btn btn_confirm" onClick={changeMedicalStatus} value="확인"></input>
            </div>
            
            <div className="container">
            <canvas id="canvas" style={{display:"none"}}></canvas>
                <div className="container-inner">
                        <div className="fragment">

                            <div style={btnLong_blue}>
                                진료를 완료해주세요.
                                <input type="button" style={btnStyle_blue} id="diagComplete" value="진료완료"></input>
                            </div>

                            <div className="mt-20"></div>

                            <div className="m-title type02">
                                환자 기본 정보
                            </div>

                            <div className="mt-20"></div>

                            <div className="m-table type01">
							<div className="thead">
								<div className="tr">
									<div className="th">이름</div>
                                    <div className="th">전화번호</div>
									<div className="th">주민등록번호</div>
									<div className="th">성별</div>
								</div>
							</div>
							<div className="tbody">
                            <div className="tr">
									<div className="td">{patient.name}</div>
									<div className="td">{patient.phone}</div>
									<div className="td">{patient.ssn}</div>
									<div className="td">{patient.gender}</div>
								</div>
							</div>
						</div>
                        </div>

                        <div className="mt-28"></div>

                        <div className="fragment">
                            <div style={{float:"left", width: "50%",}}>
                                <div className="m-title type02">
                                    증상정보
                                </div>

                                <div className="m-boxes type02">
                                    <div className="m-box box-answer">
                                        <div className="m-input-textarea type01">
                                            <textarea id="" placeholder="증상을 입력해 주세요."  defaultValue={patient.symptom} readOnly></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{marginLeft: "50%",}}>
                                <div className="m-title type02">
                                    증상사진
                                </div>
                                <div className="imgs" style={{padding: "20px",}}>
                                    <div className="m-ratioBox-wrap">
                                        {
                                            medicalImage ?
                                            <img style={{width: "100%"}} src={'data:image/jpeg;base64,'+medicalImage}></img>
                                            : ""
                                        }
                                        
                                    </div>
                                    {/*
                                    patient.images.map((image, index) =>
                                        <div className="m-ratioBox-wrap" key={index}>
                                            <img src={image.file_name} alt="" className={"pop-img"} style={{width: "100%",}} onClick={e => setPop({
                                                img_url: image.file_name
                                            })} />
                                        </div>
                                    )
                                    */    
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="mt-24"></div>
                        
                        <div style={btnLong_green}>
                            처방전을 발급하고 진료비를 수납해주세요.
                            <input type="submit" style={btnStyle_green} value="수납하기" ></input>
                        </div>
                        
                        <div className="m-title type02">
                            처방전 발급하기
                            <div className="select">
                                <input type="radio" id="radio1" name="paper" style={radioStyle} value="" checked></input><label className="radio-left" for="radio1">처방전 발급 {cnt}/5</label>
                                <input type="radio" id="radio2" name="paper" style={radioStyle} value=""></input><label className="radio-right" for="radio2">처방전 미발급</label>
                            </div>
                        </div>

                        <div className="mt-20"></div>

                        <div className="fragment">
                            <div>
                                {MyDropzone()}
                            </div>
                            {
                                medicalHistory.base64 ?
                                <div style={{width: "300px"}}>
                                    <img style={{width: "100%"}} src={'data:image/jpeg;base64,'+medicalHistory.base64}></img>
                                </div>
                                : ""
                            }
                        </div>

                        <div className="mt-20"></div>

                        <div className="box-price" style={{marginTop: '30px'}}>
                            <div className="left">
                                <div className="m-title type02">
                                    진료비 입력
                                </div>
                                
                                <div className="mt-20"></div>

                                <div className="box">
                                    <div className="m-input-text type01">
                                        <input type="number" style={{width: "40%",float:"left"}} placeholder="0" name="price" id="price" defaultValue={medicalHistory.medical_money}/>
                                        <span style={spanStyle}>원</span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="mt-20" style={{clear:"both"}}></div>

                            <div className="m-input-text type01">
                                <input type="button" className="cancleBtn" onClick={diagnosisCancle} value="진료취소"></input>
                            </div>
                        </div>
                    
                </div>
                
            </div>
            </form>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (user, token, onLogin) => {
            dispatch(login(user, token, onLogin));
        },

        setPop: (data) => {
            dispatch(setPop(data));
        }
    }
};

export default connect(null, mapDispatchToProps)(DiagnosisShow);