import React, {Fragment, useEffect} from 'react';
import {Route, Redirect, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {logout} from "../actions/commonActions";
import Time from "./Time";

const Times = ({user}) => {

    useEffect(() => {

    }, [user]);

    return (
        <div className="times">
            {user.dc_info ? user.dc_info.map((time, index) => <Time key={'time' + index} time={time} index={index} />) : ""}

        </div>
    );
};

const mapState = (state) => {
    return {

    }
};

const mapDispatch = (dispatch) => {
    return {

    }
};

export default connect(mapState, mapDispatch)(Times);
