import React, {Fragment, useEffect, useState, useRef} from 'react';
import axios from "axios";
import {setPop, setUser} from "../actions/commonActions";
import {connect} from "react-redux";
import Time from "../components/Time";
import SchedulePop from "../components/SchedulePop";
import Times from "../components/Times";
import $ from "jquery";
import Diagnosis from "../components/Diagnosis";
import CalendarPop from "../components/CalendarPop";
import {Link} from "react-router-dom";

const Main = ({user, setUser, setPop}) => {
	const getFormatDate = (date) => {
		let year = date.getFullYear();
		let month = (date.getMonth() + 1) < 10 ? ("0" + (date.getMonth() + 1)) : date.getMonth() + 1;
		let day = (date.getDate()) < 10 ? ("0" + date.getDate()) : date.getDate();

		return `${year}-${month}-${day}`;
	}

	let date = new Date();

	let [form, setForm] = useState({
		date: `${getFormatDate(date)}`
	});

	let [info, setInfo] = useState({
		doctorInfo: {},
		list: [],
		waitPatientCount: 0,
		dayFinishCount: 0,
		today: `${getFormatDate(date)}`,
		nowPatient: null
	});

	let [activeSchedulePop, setActiveSchedulePop] = useState(false);
	let [activeCalendarPop, setActiveCalendarPop] = useState(false);

	function useInterval(callback, delay) {
		const savedCallback = useRef();
		useEffect(() => {
			savedCallback.current = callback;
		}, [callback]);

		useEffect(() => {
			function tick() {
				savedCallback.current();
			}
			if (delay !== null) {
				let id = setInterval(tick, delay);
				return () => clearInterval(id);
			}
		}, [delay]);
	}

	useInterval(() => {
		getDashboard();
	}, 10000);

	useEffect(() => {
		getDashboard();

		$(".m-input-select.type01 .fake").click(function(){
			$(this).siblings(".options").toggle();
		});

		$(".m-input-select.type02 .value").click(function(){
			$(this).siblings(".options").toggle();
		});

		$(".m-input-select.type02 .option").click(function(){
			$(this).parents(".options").hide();
		});
	}, []);


	const update = (data = {}, onUpdate = () => {}) => {
		if(data.dc_info)
			data.dc_info = JSON.stringify(data.dc_info);

		axios.patch(window.domain + "/api/doctor", {
			...form,
			...data
		})
				.then(response => {
					if(data.dc_info)
						data.dc_info = JSON.parse(data.dc_info);

					setUser({
						...user,
						...data
					});

					onUpdate();
				})
	}

	const startDiagnosis = (data) => {

		if(info.nowPatient){
			alert('현재 진료 중인 환자가 있습니다.');
			return;
		}

		let formData = new FormData();
		let id = data.index;
		let patientIdx = data.idx;
		console.log(JSON.stringify(data));
		formData.append("index", id);
		formData.append("patientIdx", patientIdx);

		axios.post(window.domain + "/api/doctor/startDiagnosis", formData)
			.then(response => {
				getDashboard();
			})
			.catch(error => {
				setPop({
					title: null,
					description: error.response.data.message,
					needLogo: true,
					buttons: [<button className={"m-btn type01"} type={"button"} onClick={() => setPop(null)}>확인</button>]
				});
			})
	}

	const getDashboard = (data = {}) => {
		axios.get(window.domain + "/api/doctor/dashBoard", {
			params: {
				...form,
				...data
			}
		}).then(response => {
			setInfo(response.data);
		})
	}

	return (
			<div className="area-main">
				{activeSchedulePop ? <SchedulePop defaultValue={user.dc_info} setActiveSchedulePop={setActiveSchedulePop} update={update} /> : ""}

				{activeCalendarPop ? <CalendarPop form={form} setForm={setForm} getFormatDate={getFormatDate} setActiveCalendarPop={setActiveCalendarPop} getDashboard={getDashboard} /> : ""}
				
				<div className="container">
					<div className="sidebar">
						<div className="top">
							<div className="m-ratioBox-wrap">
								<div className="m-ratioBox">
									<img src={user.profile_file} alt="" />
								</div>
							</div>

							<div className="content">
								<h3 className="title">{user.name}</h3>
								<p className="body">{user.hospital_name}</p>
							</div>
						</div>

						<div className="states">
							<div className="state-wrap">
								<div className="state">
									<h3 className="title">진료 상태</h3>

									<div className="m-input-select type02">
										<div className={"option value " + user.dc_status}>{user.dc_status}
											<img src="/img/arrowDown-gray.png" alt=""/>
										</div>

										<div className="options">
											<div className="option 즉시" onClick={() => {update({dc_status: "즉시"})}}>즉시</div>
											<div className="option 대기" onClick={() => {update({dc_status: "대기"})}}>대기</div>
											<div className="option 종료" onClick={() => {update({ dc_status: "종료"})}}>금일 진료 종료</div>
										</div>
									</div>
								</div>
							</div>

							<div className="state-wrap">
								<div className="state">
									<h3 className="title">화상 진료</h3>

									<div className="m-input-select type02">
										<div className={"option value " + user.cam_flag}>{user.cam_flag == "true" ? "활성화" : "비활성화"} <img src="/img/arrowDown-gray.png" alt="" /></div>

										<div className="options">
											<div className="option true" onClick={() => {update({cam_flag: "true"})}}>활성화</div>
											<div className="option false" onClick={() => {update({cam_flag: "false"})}}>비활성화</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="box-time">
							<div className="box-title">
								<h3 className="title">진료시간</h3>

								<button className="m-btn type03" onClick={() => {setActiveSchedulePop(true)}}>수정하기</button>
							</div>

							<div className="times-wrap">
								<img src="/img/time.png" alt="" />

								<Times user={user} setUser={setUser} />
							</div>

						</div>
					</div>

					<div className="container-inner">
						<div className="m-boxes type01">
							<div className="box-wrap">
								<div className="box state01">
									<div className="box-title">
										<h3 className="title">날짜</h3>
										<button className="m-btn type03" onClick={() => setActiveCalendarPop(true)}>달력 보기</button>
									</div>

									<div className="content">
										<h3 className="title">{info.today}</h3>
									</div>
								</div>
							</div>

							<div className="box-wrap">
								<div className="box state02">
									<div className="box-title">
										<h3 className="title">현재 진료 중인 환자</h3>
									</div>

									<div className="content">
										{
											info.nowPatient ?
												<Fragment>
													<h3 className="title">
														{info.nowPatient.name}
														{info.nowPatient.remarks ? <span className="state">{info.nowPatient.remarks}</span> : ''}

													</h3>

													<Link to={"/diagnosis/" + info.nowPatient.index} className="btn-more">
														<img src="/img/arrowRight-gray.png" alt="" />
													</Link>
												</Fragment>
												: "없음"
										}
									</div>
								</div>
							</div>

							<div className="box-wrap">
								<div className="box">
									<div className="box-title">
										<h3 className="title">총 진료 건수</h3>
									</div>

									<div className="content">
										<h3 className="title">
											<span className="point">{info.dayFinishCount}</span>
											<span className="sub">건</span>
										</h3>

										<Link to={"/diagnosis/list/" + form.date} className="btn-more">
											<img src="/img/arrowRight-gray.png" alt="" />
										</Link>
									</div>
								</div>
							</div>

							<div className="box-wrap">
								<div className="box">
									<div className="box-title">
										<h3 className="title">총 대기 건수</h3>

										{/*<div className="m-input-checkbox type03">
											<span className="text">대기 받기</span>
											<input type="checkbox" id="test" />
												<label htmlFor="test"></label>
										</div>*/}
									</div>

									<div className="content">
										<h3 className="title">
											<span className="point">{info.waitPatientCount}</span>
											<span className="sub">명</span>
										</h3>
									</div>
								</div>
							</div>
						</div>

						<div className="mt-60"></div>

						<div className="m-title type02">환자 대기 목록</div>

						<div className="mt-20"></div>

						<div className="m-table type01">
							<div className="thead">
								<div className="tr">
									<div className="th">이름</div>
									<div className="th">주민등록번호</div>
									<div className="th">전화번호</div>
									<div className="th">접수일</div>
									<div className="th">증상</div>
									<div className="th">비고</div>
								</div>
							</div>
							<div className="tbody">
								{
									info.list.length === 0 ?
										<div className="tr empty">
											대기 환자가 없습니다.
										</div> : ""
								}

								{
									info.list.map((item, index) =>
										<Diagnosis key={item.index} item={item} startDiagnosis={startDiagnosis} />
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (data) => {
			return dispatch(setUser(data));
		},
		setPop: (data) => {
			return dispatch(setPop(data));
		}
	}
}
const mapStateToProps = (state) => {
	return {
		user: state.common.user
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);