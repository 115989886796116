import React, {Fragment, useEffect, useState} from 'react';
import {Route, Redirect, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {logout, setPop} from "../actions/commonActions";
import Time from "./Time";

const Diagnosis = ({item, startDiagnosis, setPop}) => {

    let [active, setActive] = useState(false);

    useEffect(() => {
        window.$(".pop-img").click(function(){
            alert(window.$(this).attr("src"));
        })
    }, []);

    return (
        <Fragment>
            <div className="tr" onClick={() => setActive(!active)}>
                <div className="td">{item.name}</div>
                <div className="td">{item.ssn}</div>
                <div className="td">{item.phone}</div>
                <div className="td">{item.regdate}</div>
                <div className="td">{item.symptom}</div>
                <div className="td">{item.remarks}</div>
            </div>

            {
                active ?
                    <div className="box-more">
                        <div className="fragments">
                            <div className="fragment-wrap">
                                <div className="fragment box-info">
                                    <h3 className="title">{item.name}</h3>

                                    <div className="boxes">
                                        <div className="box-wrap">
                                            <div className="box">
                                                <div className="info">
                                                    <h3 className="info-title">주민번호</h3>
                                                    <p className="info-body">{item.ssn}</p>
                                                </div>

                                                <div className="info">
                                                    <h3 className="info-title">전화번호</h3>
                                                    <p className="info-body">{item.phone}</p>
                                                </div>

                                                <div className="info">
                                                    <h3 className="info-title">접수일</h3>
                                                    <p className="info-body">{item.regdate}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="box-wrap">
                                            <div className="box">
                                                <div className="info">
                                                    <h3 className="info-title">증상</h3>
                                                    <p className="info-body">{item.symptom}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="fragment-wrap">
                                <div className="fragment box-imgs">
                                    {
                                        item.images.map(image =>
                                            <div className="m-ratioBox-wrap">
                                                <div className="m-ratioBox">
                                                    <img src={image.file_name} alt="" className={"pop-img"} onClick={() => setPop({img_url : image.file_name})} />
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="fragment-wrap">
                                {<div className="fragment box-btns">
                                    {
                                        item.status == 1 ? <button className="m-btn type02" onClick={() => startDiagnosis(item)}>진료 승인</button> : ""
                                    }
                                </div>}
                            </div>
                        </div>
                    </div>
                    : ""
            }
        </Fragment>
    );
};

const mapState = (state) => {
    return {

    }
};

const mapDispatch = (dispatch) => {
    return {
        setPop: (data) => {
            dispatch(setPop(data));
        }
    }
};

export default connect(mapState, mapDispatch)(Diagnosis);
